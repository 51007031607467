import * as Sentry from "@sentry/react";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import Utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/fr'

Sentry.init({
  dsn: "https://0a3f397f9f3a43943c0631beda338d8d@o4506060440010752.ingest.sentry.io/4506345947922432",
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
      networkDetailAllowUrls: [
        'https://apisails.timenjoy.fr', 
        "https://konnectz.timenjoy.fr", 
        "https://timenjoy.fr", 
        "htps://stripe-server.timenjoy.fr", 
        "https://crawlosaure.timenjoy.fr"
      ],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(Utc)
dayjs.extend(Timezone)
dayjs.extend(CustomParseFormat)
dayjs.extend(LocalizedFormat)
dayjs.locale('fr')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);