import { GET, POST, PUT, PATCH, DELETE } from "../helpers/fetch";
import { APISAILS_URL } from "./Config";
import { authPromise } from "./authBrowser";
import * as Sentry from "@sentry/react";

const defaultHeaders = (token) => ({
  Authorization: "Bearer " + token,
  "Content-Type": "application/json"
});

/**
 * A function that takes two parameters, mail and password, and returns a promise.
 * @param mail - The email address of the user
 * @param password - The password of the user.
 * @returns The response from the server.
 */
export const login = (mail, password) => {
  return POST(APISAILS_URL + "/userpro/login", { mail, password }).then(
    (res) => {
      try{
      Sentry.setUser({ email: mail });
      sessionStorage.removeItem("context");
      localStorage.setItem("lastLogin", new Date().toISOString());
      }catch(e){
      }
      return res;
    }
  );
};

/**
 * It returns a promise that resolves to an array of users
 * @param id - the id of the company
 * @returns The company users
 */
export const getCompanyUsers = (id) => {
  return authPromise
    .then((token) =>
      GET(`${APISAILS_URL}/users/company/${id}`, defaultHeaders(token))
    )
    .then((res) => {
      return res;
    });
};

/**
 * It takes in a user_id and a company_id and returns a promise that will resolve to a response object.
 * @param user_id - the id of the user who is joining the company
 * @param company_id - the id of the company you want to join
 * @returns The company_id and user_id are being returned.
 */
export const joinCompany = (user_id, company_id) => {
  return authPromise
    .then((token) =>
      POST(
        `${APISAILS_URL}/companyuser`,
        { company_id, user_id },
        defaultHeaders(token)
      )
    )
    .then((res) => {
      console.log(res);
      return res;
    });
};

/**
 * It removes a user from a company.
 * @param user_id - the user id of the user you want to remove from the company
 */
export const leaveCompany = (user_id) => {
  return authPromise
    .then((token) =>
      POST(
        `${APISAILS_URL}/userpro/company/remove`,
        { user_id },
        defaultHeaders(token)
      )
    )
    .then((res) => {
      console.log(res);
      return res;
    });
};

/**
 * It returns a promise that resolves to the user object with the given email
 * @param email - the email of the user you want to get the userId for
 * @returns The user id of the user with the email address passed in.
 */
export const getUserId = (email) => {
  return authPromise
    .then((token) =>
      GET(
        `${APISAILS_URL}/userpro?where={"email": "${email}"}`,
        defaultHeaders(token)
      )
    )
    .then((res) => res.rows[0]);
};

/**
 * It returns a promise that resolves to an array of objects, each object representing an account
 * @param id - the id of the company
 * @returns An array of objects.
 */
export const getAccounts = (id) => {
  return authPromise
    .then((token) =>
      GET(`${APISAILS_URL}/company/${id}/apiAccounts`, defaultHeaders(token))
    )
    .then((res) => res);
};

/**
 * It returns a promise that resolves to an array of objects, each of which contains a company's Stripe
 * subscription information
 * @param id - the id of the company
 * @returns The subscription object
 */
export const getSubscription = (id) => {
  return authPromise
    .then((token) =>
      GET(
        `${APISAILS_URL}/stripeSubscriptions?where={"company_id": "${id}"}`,
        defaultHeaders(token)
      )
    )
    .then((res) => res);
};

/**
 * It returns a promise that resolves to an array of objects, each of which contains an app secret
 * @param id - the id of the company
 * @returns An array of objects.
 */
export const getAppSecret = (id) => {
  return authPromise
    .then((token) =>
      GET(
        `${APISAILS_URL}/apiAccount?where={"company_id":"${id}"}`,
        defaultHeaders(token)
      )
    )
    .then((res) => res.rows);
};

/**
 * It sends a POST request to the API with the email address of the user who wants to reset their
 * password
 * @param email - the email address of the user who wants to reset their password
 * @returns A promise that will resolve to the response from the server.
 */
export function askForPasswordReset(email) {
  return authPromise.then((token) =>
    POST(
      APISAILS_URL + "/userpro/passwordlost",
      { email },
      defaultHeaders(token)
    )
  );
}

/**
 * It takes a userId, password, and password_check, and returns a promise that resolves to the result
 * of a PUT request to the API.
 * @param userId - the user's id
 * @param password - the new password
 * @param password_check - the password confirmation
 * @returns A promise that will resolve to the response from the server.
 */
export function resetPassword(userId, password, password_check) {
  return authPromise.then((token) =>
    PUT(
      `${APISAILS_URL}/userpro/${userId}/resetpassword`,
      { password, password_check },
      defaultHeaders(token)
    )
  );
}

/**
 * It takes a userId, old_password, password, and password_check, and returns a promise that resolves
 * to the result of a PUT request to the API.
 * @param userId - the user's id
 * @param old_password - The user's current password
 * @param password - the new password
 * @param password_check - The password confirmation
 * @returns A promise that will resolve to the response from the server.
 */
export function updatePassword(userId, old_password, password, password_check) {
  return authPromise.then((token) =>
    PUT(
      `${APISAILS_URL}/userpro/${userId}/changepassword`,
      { old_password, password, password_check },
      defaultHeaders(token)
    )
  );
}

/**
 * Get the session info for the given session id, using the auth token.
 * @param sessionId - The session ID of the user you want to get the info of.
 * @returns A promise that resolves to the session info.
 */
export function getSessionInfo(sessionId) {
  return authPromise.then((token) =>
    GET(
      `${APISAILS_URL}/companyusersession/${sessionId}`,
      defaultHeaders(token)
    )
  );
}

/**
 * It creates an API account, then activates it, then adds a whitelist entry for the URL
 * @param params - {
 * @returns The id of the api account
 */
export const createApiAccount = async (params) => {
  let id;
  const tokenHeader = await authPromise.then(defaultHeaders);
  return POST(APISAILS_URL + "/apiAccount", params, tokenHeader)
    .then((res) => {
      id = res.id;
    })
    .then((res) =>
      PATCH(
        `${APISAILS_URL}/apiAccount/${id}`,
        {
          active: true,
          app_secret: res,
          isFreemium: !params.premium,
          type: "customer_api"
        },
        tokenHeader
      )
    )
    .then((res) => {
      POST(
        APISAILS_URL + "/whiteList",
        { api_account_id: id, url: params.url, active: true },
        tokenHeader
      );
      return res;
    });
};

/**
 * It registers a user and a company, and sends some emails
 * @param params - {
 */
export const registerAll = (params) => {
  let user;
  return authPromise.then((token) =>
    POST(APISAILS_URL + "/userpro/register", params.user, defaultHeaders(token))
      .then((res) => {
        user = res;
        user.email = params.user.email;
        user.job = params.user.job;
        updateRegisterUser({ user: params.user, id: res.id });
        return registerCompany({
          id: res.id || params.id,
          company: params.company
        });
      })
      .then((res) => {
        addUserSendingBlue(user);
        sendTimenjoyMessages(params);
        return res;
      })
  );
};

/**
 * It takes a user object and an id, deletes the password and password_check properties, and then sends
 * a PUT request to the API with the updated user object
 * @param params - {
 */
const updateRegisterUser = (params) => {
  delete params.user.password;
  delete params.user.password_check;
  authPromise.then((token) =>
    PUT(
      APISAILS_URL + "/userpro/" + params.id,
      params.user,
      defaultHeaders(token)
    )
  );
};

/**
 * It takes an array of emails, a company name, and a url, and sends an email to each email address
 * with the company name and url
 * @param emails - an array of emails to send invites to
 * @param company - the company name
 * @param url - the url of the app
 */
export const sendInviteEmails = (emails, company, url) => {
  return authPromise.then((token) =>
    POST(
      `${APISAILS_URL}/sendInvites`,
      { emails, company, url },
      defaultHeaders(token)
    ).then((res) => {
      console.log(res);
      return res;
    })
  );
};

/**
 * It creates a new user and then joins the user to a company.
 * @param nuser - the user object
 * @param company_id - the id of the company you want to join
 */
export const userJoinCompany = (nuser, company_id) => {
  return authPromise.then((token) =>
    POST(APISAILS_URL + "/userpro/register", nuser, defaultHeaders(token))
      .then(async (res) => {
        updateRegisterUser({ user: nuser, id: res.id });
        return res;
      })
      .then((res) => {
        return joinCompany(res.id, company_id).then((res) => {
          return res;
        });
      })
  );
};

/**
 * It takes a parameter object, makes a POST request to the API, and then updates the company in the
 * Redux store
 * @param params - {
 * @returns The company object
 */
const registerCompany = async (params) => {
  const tokenHeader = await authPromise.then(defaultHeaders);
  return POST(APISAILS_URL + "/company", params.company, tokenHeader).then(
    (res) => {
      POST(
        APISAILS_URL + "/companyuser",
        { company_id: res.id, user_id: params.id },
        tokenHeader
      );
      params.company.id = res.id;
      updateCompany(params.company);
      return res;
    }
  );
};

/**
 * It takes a company object, and returns a promise that will resolve to the updated company object
 * @param company - the company object to be updated
 * @returns A promise that will resolve to the updated company.
 */
const updateCompany = (company) => {
  return authPromise.then((token) =>
    PUT(`${APISAILS_URL}/company/${company.id}`, company, defaultHeaders(token))
  );
};

/**
 * It updates the user and company information
 * @param params - {
 * @returns An array of two promises.
 */
export const updateInfos = async (params) => {
  let firstRes = await updateCompany(params.company);
  let res = await updateUser(params.user);
  return [res, firstRes];
};

/**
 * It updates a user.
 * @param user - the user object to be updated
 * @returns A promise that will resolve to the updated user object.
 */
const updateUser = (user) => {
  return authPromise.then((token) =>
    PUT(`${APISAILS_URL}/userpro/${user.id}`, user, defaultHeaders(token))
  );
};

/**
 * It adds a user to a SendinBlue list and sends them an email
 * @param user - {
 * @returns A promise that resolves to the response from the POST request.
 */
const addUserSendingBlue = async (user) => {
  const tokenHeader = await authPromise.then(defaultHeaders);
  if (process.env.REACT_APP_MODE !== "production") return;
  return POST(
    APISAILS_URL + "/sendinblue/contacts",
    { listId: 8, user },
    tokenHeader
  ).then(() =>
    POST(
      APISAILS_URL + "/sendinblue/sendEmailTemplate",
      { emailTo: user.email, templateId: 452 },
      tokenHeader
    )
  );
};

/**
 * It sends a message to the Timenjoy sales team and to the Slack channel #mytimenjoy-register
 * @param params - {
 */
const sendTimenjoyMessages = async (params) => {
  if (process.env.REACT_APP_MODE !== "production") return;
  /* Creating a template literal that will be used to send an email to the user. */
  const html = `<p>La société <b>${
    params.company.name
  }</b> s'est enregistrée sur Mytimenjoy</p>\n
    <p>Identité: ${params.user.firstname + " " + params.user.lastname}. </p>\n
    <p>Email: ${params.user.email} </p>\n
    <p>Tél Pro: ${params.user.phone} \n
    <p>Tél Perso: ${params.user.phone} \n
    <p>Localisation: ${params.company.zip_code + " " + params.company.city}\n`;

  const tokenHeader = await authPromise.then(defaultHeaders);

  /* Sending an email to the sales-support@timenjoy.fr email address. */
  POST(
    APISAILS_URL + "/sendinblue/sendEmail",
    {
      htmlContent: html,
      sender: { email: params.user.email, name: "My Time N'Joy" },
      subject: `La société ${params.company.name} s'est enregistrée sur MyTimeNJoy`,
      user: { email: "sales-support@timenjoy.fr" }
    },
    tokenHeader
  );

  /* Sending a POST request to the API with the user's information. */
  POST(
    APISAILS_URL + "/slack/message",
    {
      company_name: params.company.name,
      email: params.user.email,
      first_name: params.user.firstname,
      last_name: params.user.lastname,
      localisation: params.company.zip_code + " " + params.company.city,
      mytimenjoy_register: true,
      phone: params.user.phone
    },
    tokenHeader
  );
};
