const devPrices = [
  {
    name: "Freemium",
    eventCount: "1 évènement par mois",
    mensuel: {
      id: "",
      price: 0
    },
    annuel: {
      id: "",
      price: 0
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencez 1 évènement par mois"
    ],
    grid: ["1 évènement par mois", true, true, false, false, false]
  },
  {
    name: "Basique",
    eventCount: "5 évènements par mois",
    mensuel: {
      id: "price_1Kj1jjHe8JvDQ9ZmAxbZ8DtJ",
      price: 12.99
    },
    annuel: {
      id: "price_1Kj1YOHe8JvDQ9ZmCU48PlI5",
      price: 119,
      equivalent_mensuel: 9.99
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencez jusqu'à 5 évènements par mois"
    ],
    grid: ["5 évènements par mois", true, true, true, false, false]
  },
  {
    name: "Premium",
    eventCount: "Illimité",
    // mensuel: {
    //   id: "price_1LhKZ5He8JvDQ9ZmUFh58sRW",
    //   price: 1
    // },
    // annuel: {
    //   id: "price_1LhKZ5He8JvDQ9ZmUFh58sRW",
    //   price: 1,
    //   equivalent_mensuel: 0.08
    // },
    mensuel: {
      id: "price_1Kj1lCHe8JvDQ9ZmLKTlLl7s",
      price: 24.99
    },
    annuel: {
      id: "price_1Kj1m2He8JvDQ9ZmH0KcGgQn",
      price: 239,
      equivalent_mensuel: 19.99
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencement illimité d'évènements",
      "Analysez vos statistiques",
      "Diffusion sur nos réseaux partenaires",
      "Créez et customisez votre widget"
    ],
    grid: ["Illimité", true, true, true, true, true]
  }
];
const prodPrices = [
  {
    name: "Freemium",
    eventCount: "1 évènement par mois",
    mensuel: {
      id: "",
      price: 0
    },
    annuel: {
      id: "",
      price: 0
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencez 1 évènement par mois"
    ],
    grid: ["1 évènement par mois", true, true, false, false, false]
  },
  {
    name: "Basique",
    eventCount: "5 évènements par mois",
    mensuel: {
      id: "price_1KqBQ3He8JvDQ9ZmEQqz24b0",
      price: 12.99
    },
    annuel: {
      id: "price_1KqBQ7He8JvDQ9Zm4sONYWkC",
      price: 119,
      equivalent_mensuel: 9.99
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencez jusqu'à 5 évènements par mois"
    ],
    grid: ["5 évènements par mois", true, true, true, false, false]
  },
  {
    name: "Premium",
    eventCount: "Illimité",
    // mensuel: {
    //   id: "price_1LhH7KHe8JvDQ9Zmq7Gap1Vy",
    //   price: 1
    // },
    // annuel: {
    //   id: "price_1LhH7KHe8JvDQ9Zmq7Gap1Vy",
    //   price: 1,
    //   equivalent_mensuel: 1
    // },
    mensuel: {
      id: "price_1KqBPzHe8JvDQ9Zmid6TVVYu",
      price: 24.99
    },
    annuel: {
      id: "price_1KqBPtHe8JvDQ9ZmbhsmqRH1",
      price: 239,
      equivalent_mensuel: 19.99
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencement illimité d'évènements",
      "Analysez vos statistiques",
      "Diffusion sur nos réseaux partenaires",
      "Créez et customisez votre widget"
    ],
    grid: ["Illimité", true, true, true, true, true]
  }
];

const prices =
  process.env.REACT_APP_MODE === "development" ? devPrices : prodPrices;

export const hiddenPrices = [
  {
    name: "Premium",
    eventCount: "Illimité",
    mensuel: {
      id: "price_1LhKZ5He8JvDQ9ZmUFh58sRW",
      price: 1
    },
    annuel: {
      id: "price_1LhKZ5He8JvDQ9ZmUFh58sRW",
      price: 1,
      equivalent_mensuel: 0.08
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencement illimité d'évènements",
      "Analysez vos statistiques",
      "Diffusion sur nos réseaux partenaires",
      "Créez et customisez votre widget"
    ],
    grid: ["Illimité", true, true, true, true, true]
  },
  {
    name: "Premium",
    eventCount: "Illimité",
    mensuel: {
      id: "price_1LhH7KHe8JvDQ9Zmq7Gap1Vy",
      price: 1
    },
    annuel: {
      id: "price_1LhH7KHe8JvDQ9Zmq7Gap1Vy",
      price: 1,
      equivalent_mensuel: 1
    },
    advantages: [
      "Accédez à Mytimenjoy",
      "Gérez vos évènements",
      "Référencement illimité d'évènements",
      "Analysez vos statistiques",
      "Diffusion sur nos réseaux partenaires",
      "Créez et customisez votre widget"
    ],
    grid: ["Illimité", true, true, true, true, true]
  }
];

const allPaidPlans = [...prices.slice(1), ...hiddenPrices]; // Freemium excluded but hidden plans included

export const findPlan = (price_id) => {
  for (const plan of allPaidPlans.slice(1)) {
    if (plan.mensuel.id === price_id) {
      return {
        cycle: "mensuel",
        plan
      };
    }
    if (plan.annuel.id === price_id) {
      return {
        cycle: "annuel",
        plan
      };
    }
  }
  return {
    plan: prices[0]
  };
};

export default prices;
